import styled from "styled-components";
import { Link } from "react-router-dom";

interface MenuData {
  activeItem?: boolean;
}

export const PanelWrapper = styled.div`
  width: 300px;
  min-width: 300px;
  min-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const AvatarImage = styled.img`
  width: 120px;
  height: 120px;
  min-height: 120px;
  object-position: center;
  object-fit: cover;
  display: flex;
  border-radius: 50%;
  border: 4px solid white;
  box-shadow: 0px 0px 0px 4px #6fa367;
  margin-top: 36px;
`;
export const SearchInput = styled.div`
  width: 220px;
  height: 42px;
  min-height: 42px;
  display: flex;
  align-items: center;
  border: 2px solid #e4e4e4;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 20px;
  margin-top: 20px;

  input {
    width: calc(100% - 20px);
    color: #9c9c9c;
    font-size: 15px;
    margin-left: 10px;
  }
`;

export const UserName = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #2957a4;
  margin-top: 14px;
`;

export const MenuItem = styled.button<MenuData>`
  width: 100%;
  height: 45px;
  color: #9c9c9c;
  font-size: 15px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  cursor: pointer;
  transition: all ease 0.3s;
  text-decoration: none;
  background-color: ${(props) =>
    props.activeItem ? "#EAF1E8" : "trnasparent"};

  svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: #eaf1e8;
  }
`;

export const NavbarMenu = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-top: 48px;
`;
