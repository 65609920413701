import axios from "axios";
import { toast } from "react-toastify";

export const api = axios.create({
  baseURL: 'https://api.garciamonaco.com/'
//   baseURL: 'https://apiv2.appconsultoriaambiental.com.br/',
//   baseURL: 'http://localhost:1337/',
});

export const getUserCompany = async (userEmail: string) => {
    try {
        const response = await api.get(
            `/api/companies?filters[users][email][$contains]=${userEmail}`
          )
        return(response.data.data[0].attributes.name);
    } catch (error) {
        return('Seja Bem Vindo!')
    }
}

export const getArchives = async (userEmail: string) => {
    try {
        const data = [];
        let page = 1;
        let pageNum;
        let response;
        while(page <= pageNum || page === 1){
        response = await api.get(
            `/api/documents?filters[company][users][email][$contains]=${userEmail}&populate=file&pagination[page]=${page}&pagination[pageSize]=100`
            )
            data.push(...response.data.data)
            pageNum = response.data.meta.pagination.pageCount
            page += 1;
        }
        return data
    } catch (error) {
      toast.error(
        "Não foi possivel carregar os arquivos! Tente novamente mais tarde"
      );
      return []
    }
  }

  export const getFolders = async (userEmail: string) => {
    try {
        const data = [];
        let page = 1;
        let pageNum;
        let response;
        while(page <= pageNum || page === 1){
        response = await api.get(
            `/api/file-folders?filters[company][users][email][$contains]=${userEmail}&pagination[page]=${page}&pagination[pageSize]=100`
            )
            data.push(...response.data.data)
            pageNum = response.data.meta.pagination.pageCount
            page += 1;
        }
        return data
    } catch (error) {
      toast.error(
        "Não foi possivel carregar as pastas! Tente novamente mais tarde"
      );
      return []
    }
  }