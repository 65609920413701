import React from 'react';
import { Container } from './styled';

export const HelpPage = () => {

    return(
        <Container>
            <div>
                <h1>Precisa de ajuda ?</h1>
                <h3>Fale conosco através dos contatos abaixo:</h3>
                <span>Email: gmonaco@garciamonaco.com.br</span>
                <br></br>
                <span>Telefone: (35) 3623 2396</span>
            </div>
        </Container>
    )
} 