import React from "react";
import GlobalStyle from "./Styles/global";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./hooks/useAuth";
import { ArchivesProvider } from "./hooks/useArchives";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ArchivesProvider>
          <GlobalStyle />
          <Router />
        </ArchivesProvider>
      </AuthProvider>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
