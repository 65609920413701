import React, { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import {
  createIcon,
  Input,
  InputWrapper,
  LeftIconWrapper,
  RightIconWrapper,
} from "./styled";
import { useField } from "@unform/core";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  rightIcon?: JSX.Element;
  leftIcon?: JSX.Element;
  marginBottom?: string;
  marginTop?: string;
  onClickRight?: () => void;
  name: string;
}

const InputIcon: React.FC<InputProps> = ({
  leftIcon,
  rightIcon,
  marginBottom,
  marginTop,
  onClickRight,
  name,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const InputRef = useRef(null);

  useEffect(
    () =>
      registerField({
        name: fieldName,
        ref: InputRef.current,
        path: "value",
      }),
    [fieldName, registerField]
  );

  return (
    <InputWrapper
      marginTop={marginTop}
      marginBottom={marginBottom}
      focused={isFocused}
    >
      <LeftIconWrapper>
        {leftIcon !== undefined && createIcon(leftIcon.type)}
      </LeftIconWrapper>
      <Input
        {...rest}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        ref={InputRef}
        defaultValue={defaultValue}
      />
      <RightIconWrapper onClick={onClickRight} type="button">
        {rightIcon !== undefined && createIcon(rightIcon.type)}
      </RightIconWrapper>
    </InputWrapper>
  );
};

export default InputIcon;
