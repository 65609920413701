import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { api, getArchives, getFolders } from "../services/api";
import { useAuth } from "./useAuth";

interface ArchiveProviderData {
    atualPath: AtualPath;
    setAtualPath: any;

    atualFiles: object[];
    setAtualFiles: any;

    atualFolders: object[];
    setAtualFolders: any;

    data: any;
    foldersData: any;

    viewFile: string;
    setViewFile: any;

    isLoading: boolean;
}

interface AtualPath {
    prevPath: string,
    id: string,
    label: string
}

const ArchiveContext = createContext<ArchiveProviderData>(
  {} as ArchiveProviderData
);

const ArchivesProvider: React.FC = ({ children }) => {
  const [atualPath, setAtualPath] = useState<AtualPath>({
    prevPath: '',
    id: 'root',
    label: 'Raiz'
  });
  const [atualFiles, setAtualFiles] = useState([]);
  const [atualFolders, setAtualFolders] = useState([]);

  const [data, setData] = useState<any[]>([]);
  const [foldersData, setFoldersData] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const [viewFile, setViewFile] = useState("");

  const { user } = useAuth();

  const sortFunction = (itemA: any, itemB: any) => {
    if (itemA.attributes.name > itemB.attributes.name) {
        return 1;
      }
      if (itemA.attributes.name < itemB.attributes.name) {
        return -1;
      }
      return 0;
  };

  const fetchArchives = useCallback(async () => {
    setIsLoading(true);
    const archives = await getArchives(user.email)
    setData(archives.sort(sortFunction))
    setIsLoading(false);
  }, [user]);

  const fetchFolders = useCallback(async () => {
    setIsLoading(true);
    const folders = await getFolders(user.email)
    setFoldersData(folders.sort(sortFunction))
    setIsLoading(false);
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchArchives();
      fetchFolders();
    }
  }, [user, fetchArchives, fetchFolders]);

  useEffect(() => {
    setIsLoading(true)
    const filesData: any = []
    const insiderFolders: any = []

    data.forEach((file: any) => {
      if (file.attributes.parent_folder === atualPath.id) {
        filesData.push(file.attributes);
      }
    });

    foldersData.forEach((folder: any) => {
      if (folder.attributes.parent_id === atualPath.id) {
        insiderFolders.push(folder)
      }
    });

    setAtualFolders(insiderFolders)
    setAtualFiles(filesData)
    setIsLoading(false)
  }, [data, atualPath, foldersData]);

  return (
    <ArchiveContext.Provider
      value={{
        atualPath,
        setAtualPath,
        atualFiles,
        setAtualFiles,
        atualFolders,
        setAtualFolders,
        data,
        foldersData,
        isLoading,
        viewFile,
        setViewFile,
      }}
    >
      {children}
    </ArchiveContext.Provider>
  );
};

function useArchives() {
  const context = useContext(ArchiveContext);

  if (!context) {
    throw new Error("useArchive must be used within an ArchivesProvider!");
  }

  return context;
}

export { useArchives, ArchivesProvider };
