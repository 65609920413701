import React from "react";
import { Route, Switch } from "react-router-dom";
import LoginPage from "../Pages/Login";
import Navigator from "../Pages/Navigator";
import PrivateRoute from "./privateRoute";

const Router: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={LoginPage} />
      <PrivateRoute path="/dashboard" component={Navigator} isPrivate />
    </Switch>
  );
};

export default Router;
