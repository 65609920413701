import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../Pages/Dashboard";
import { HelpPage } from "../Pages/Help";
import Profile from "../Pages/Profile";
import { VisualizerPage } from "../Pages/Visualizer";
import PrivateRoute from "./privateRoute";

const NavbarRouter: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute
        path="/dashboard/documents"
        component={Dashboard}
        isPrivate
      />
      <PrivateRoute path="/dashboard/profile" component={Profile} isPrivate />
      <PrivateRoute path="/dashboard/help" component={HelpPage} isPrivate />
      <PrivateRoute
        path="/dashboard/view"
        component={VisualizerPage}
        isPrivate
      />
    </Switch>
  );
};

export default NavbarRouter;
