import React from "react";
import { CgProfile } from "react-icons/cg";
import { IoMdHelpCircleOutline } from "react-icons/io";

interface ItemData {
  title: string;
  icon: JSX.Element;
  path: string;
}

export const NavbarData: ItemData[] = [
  {
    title: "Alterar Senha.",
    icon: <CgProfile />,
    path: "/profile",
  },
  {
    title: "Precisa de Ajuda?",
    icon: <IoMdHelpCircleOutline />,
    path: "/help",
  },
];
