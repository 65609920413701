import styled from 'styled-components'
import bg from '../../Assets/dashboard_bg.png'

export const Container = styled.div`
    display: flex;
    width: 100%;
`;

export const MainPanel = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    gap: 16px;
    background-image: url(${bg});
    background-size: cover;
    background-position: center;
    align-items: center;
`;

export const Title = styled.span`
  font-size: 43px;
  width: 100%;
  font-weight: 500;
  color: white;
  padding-left: 32px;
  margin-top: 60px;
`;

export const Subtitle = styled.span`
  color: white;
  font-size: 18px;
`;

export const AvatarImage = styled.img`
    width: 200px;
    height: 200px;
    min-height: 120px;
    object-position: center;
    object-fit: cover;
    display: flex;
    border-radius: 50%;
    border: 4px solid transparent; 
    box-shadow: 0px 0px 0px 4px #6FA367;
    margin-top: 36px;
`;

export const LoginButton = styled.button`
  display: flex;
  margin-top: 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 17px;
  font-weight: bold;
  color: white;
  background: linear-gradient(65.49deg, #6fa367 29.62%, #8fce85 82.93%);
  border-radius: 10px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all ease 0.2s;

  &:hover {
    filter: brightness(1.07);
  }

  &:active {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
    transform: translateY(3px);
  }
`;