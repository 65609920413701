import React from 'react';
import {
  AiFillFolderOpen,
  AiOutlineArrowLeft,
  AiOutlineFilePdf
} from 'react-icons/ai'
import {CardWrapper} from './styled'

interface FolderCardData{
    name?: string;
    icon?: string;
    onClick?: (any: any) => void;
}

const FolderCard: React.FC<FolderCardData> = ({name, icon, onClick}: FolderCardData) => {
    return (
        <CardWrapper onClick={onClick}>
            {(icon === 'folder')
              ? <AiFillFolderOpen/>
              : (icon === 'back')
                ? <AiOutlineArrowLeft/>
                : <AiOutlineFilePdf/>}
            <h1>{name}</h1>
        </CardWrapper>
    )
}

export default FolderCard