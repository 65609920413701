import styled from "styled-components";
import bg from "../../Assets/dashboard_bg.png";

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;

  div{
    display: flex;
    flex-direction: column;
    width: 500px;
    padding: 2rem;
    height: 300px;
    border-radius: 10px;
    background-color: white;
    align-items: center;
    justify-content: center;

    h3{
        margin-bottom: 20px;
    }
  }
`;