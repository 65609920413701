import React, { useCallback, useState } from "react";
import InputIcon from "../../Components/InputIcon";
import {
  ScreenWrapper,
  LoginBox,
  Title,
  LostPasswordButton,
  LoginButton,
  HeaderTitle,
  LoggoBrand,
} from "./styled";
import { MdLockOutline } from "react-icons/md";
import { BiUser } from "react-icons/bi";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import CryptoJS from 'crypto-js';
import LogoWhite from "../../Assets/logo_white.png";

interface FormData {
  identifier: string;
  password: string;
}

const LoginPage = () => {
  const [showPasswordIcon, setShowPasswordIcon] = useState(false);

  const { signIn } = useAuth();

  const handleShowPassword = useCallback(() => {
    setShowPasswordIcon(!showPasswordIcon);
  }, [showPasswordIcon]);

  const ShowPassword = showPasswordIcon ? (
    <AiOutlineEyeInvisible />
  ) : (
    <AiOutlineEye />
  );

  const handleSubmit = useCallback(
    async (data: FormData) => {
        const password = CryptoJS.SHA256(data.password).toString()
        signIn({ ...data, password: password })
    },
    [signIn]
  );
  return (
    <ScreenWrapper>
      <LoggoBrand src={LogoWhite} />
      <HeaderTitle>Consultoria Ambiental 4.0</HeaderTitle>
      <LoginBox>
        <Title>LOGIN</Title>
        <Form onSubmit={handleSubmit}>
          <InputIcon
            placeholder="Email"
            marginBottom="20px"
            leftIcon={<BiUser />}
            name="identifier"
          />
          <InputIcon
            placeholder="Senha"
            name="password"
            leftIcon={<MdLockOutline />}
            rightIcon={ShowPassword}
            type={showPasswordIcon ? "text" : "password"}
            onClickRight={handleShowPassword}
          />
          {/* <LostPasswordButton>Esqueceu sua senha ?</LostPasswordButton> */}
          <LoginButton>LOGIN</LoginButton>
        </Form>
      </LoginBox>
    </ScreenWrapper>
  );
};

export default LoginPage;
