import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    *{
        box-sizing:border-box;
        outline:none;
        border: none;
        padding: 0;
        margin: 0;
        font-family: 'Roboto', sans-serif;
    }

    button {
        background-color: transparent;
    }

    #root{
        height: 100vh;
    }

`;
