import React from 'react'
import styled from 'styled-components'

export const createIcon = (type: string) => {  
    return React.createElement(type, { style:{color: '#9C9C9C', widht: '18px', height: '18px', marginLeft: '10px', marginRight: '10px', }});
}

interface Props {
    marginBottom?: string;
    marginTop?: string;
    focused?: boolean;
}

export const InputWrapper = styled.div<Props>`
    height: 40px;
    width: 100%;
    border: ${props => props.focused ? '2px solid #6FA367': '2px solid rgba(160, 160, 160, 0.27)'};
    transition: all ease 0.3s;
    border-radius: 10px;
    margin-bottom: ${props => props.marginBottom};
    margin-top: ${props => props.marginTop};
    display: flex;
    background-color: white;

    &:hover{
        border: 2px solid #6FA367;
    }
   
`

export const Input = styled.input`
    height: 36px;
    border-radius: 10px;

    &:focus ${InputWrapper}{
        border: 2px solid #6FA367;
    }

`

export const LeftIconWrapper = styled.div`
    display: flex;
    height:36px;
    align-items: center;
    justify-content: center;
`

export const RightIconWrapper = styled.button`
    display: flex;
    height:36px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
`
