import styled from "styled-components";
import bg from "../../Assets/dashboard_bg.png";
import { FiLogOut } from "react-icons/fi";

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  align-items: center;
  justify-content: center;
`;

export const MainPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  min-height: 100vh;
  align-items: center;
`;

export const LogoutButton = styled.button`
  position: absolute;
  top: 50px;
  right: 70px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  background-color: transparent;
  color: white;
  width: 100px;
  height: 40px;
  transition: all ease 0.3s;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

export const Title = styled.span`
  width: 100%;
  font-size: 43px;
  font-weight: 500;
  color: white;
  padding-left: 32px;
  margin-top: 60px;
`;

export const Subtitle = styled.span`
  color: white;
  width: 100%;
  padding-left: 32px;
  margin-top: 24px;
  font-size: 18px;
`;

export const DocumentsHeader = styled.div`
  width: calc(100% - 64px);
  /* border: 2px solid white; */
  background-color: white;
  background-color: rgba(41, 87, 164, 0.3);
  height: 46px;
  min-height: 46px;
  border-radius: 10px;
  margin-top: 48px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
  padding-left: 24px;

  h1 {
    /* color: #2957a4; */
    color: white;
    font-size: 20px;
    font-weight: bold;
  }

  div{
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 8px;
      input{
        font-size: 20px;
        font-weight: bold;
        color: white;
        background-color: transparent;
        &::placeholder{
            color: #ababab;
        }
      }
  }
`;



export const FileExplorer = styled.div`
  display: grid;
  width: calc(100% - 64px);
  margin: 36px 0;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-gap: auto;
  align-items: center;
  row-gap: 48px;
  grid-column-gap: 16px;
  overflow: hidden;
`;

export const LogoutIcon = styled(FiLogOut)`
  margin-right: 10px;
`;

export const LoadingCard = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 10px;
  background-color: rgba(190, 190, 190, 0.5);
  animation: ease fade 1.6s infinite;
  @keyframes fade {
    0% {
      opacity: 0.1;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.1;
    }
  }
`;
