import styled from "styled-components";
import Background from "../../Assets/background.png";

export const ScreenWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-image: url(${Background});
`;

export const LoginBox = styled.div`
  width: 300px;
  height: 375px;
  background-color: white;
  box-shadow: 0px 11px 10px 2.65979px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const Title = styled.span`
  font-weight: 900;
  font-size: 30px;
  color: #9c9c9c;
  margin-bottom: 50px;
`;

export const LoginForm = styled.form``;

export const LostPasswordButton = styled.div`
  width: 100%;
  margin-top: 20px;
  color: #2957a4;
  font-weight: 500;
  text-align: right;
  font-size: 14px;
  cursor: pointer;
`;

export const LoginButton = styled.button`
  display: flex;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 17px;
  font-weight: bold;
  color: white;
  background: linear-gradient(65.49deg, #6fa367 29.62%, #8fce85 82.93%);
  border-radius: 10px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all ease 0.2s;

  &:hover {
    filter: brightness(1.07);
  }

  &:active {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
    transform: translateY(3px);
  }
`;

export const HeaderTitle = styled.div`
  font-size: 42px;
  color: white;
  font-family: "Be Vietnam";
  text-shadow: 0px 2.9453px 2.9453px rgba(0, 0, 0, 0.35);
  text-transform: uppercase;
  position: absolute;
  bottom: calc(50% + 250px);
`;

export const LoggoBrand = styled.img`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 200px;
`;
