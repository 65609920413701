import { stringify } from "querystring";
import { createContext, useCallback, useState, useContext } from "react";
import { toast } from "react-toastify";
import { api } from "../services/api";

interface AuthState {
  token: string;
  user: any;
}

interface SignInCredentials {
  identifier: string;
  password: string;
}

interface AuthContextData {
  user: any;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  editUser(data: any): any;
}



const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem("ca.token");
    const user = localStorage.getItem("ca.user");
    if (token && user) {
        api.defaults.headers['Authorization'] = `Bearer ${token}`
        return { token, user: JSON.parse(user) };
    }
    return {} as AuthState;
  });

  const signIn = useCallback(async (credentials: SignInCredentials) => {
    try {
        const response = await api.post('api/auth/local', credentials)
        if (response.data.user.class !== 'client') {
            throw new Error('Não é um cliente')
        }
        const { jwt: token, user } = response.data;
      localStorage.setItem("ca.token", token);
      localStorage.setItem("ca.user", JSON.stringify(user));
      api.defaults.headers['Authorization'] = `Bearer ${token}`
      setData({ token, user });

      toast.success("Seja Bem Vindo!");
    } catch (error: any) {
        if (error.message === 'Não é um cliente')
            toast.error('O Usuário não é um cliente')
        else if(error?.response?.data?.error?.message === 'Invalid identifier or password')
            toast.error("Usuário ou senha invalida");
        else
            toast.error("Ocorreu um erro com a conexão");
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("ca.user");
    localStorage.removeItem("ca.token");
    setData({} as AuthState);
  }, []);

    const editUser = useCallback(async (changes) => {
        try {
            await api.put(`api/users/${data.user.id}`, changes)
            toast.success('Senha alterada.')
        } catch (error) {
            toast.error('Alteração de senha não foi realizada.')
        }
        return null
    }, [data.user])

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, editUser }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
