import { useArchives } from "../../hooks/useArchives";
import styled from 'styled-components';
import { useHistory } from "react-router";

interface VisualizerProps {
  url?: string;
}

const ClosePdf = styled.button`
    position: absolute;
    right: 132px;
    top: 15px;
    height: 20px;
    width: 64px;
    color: #FFF;
    cursor: pointer;
    background-color: transparent;
    transition: all ease 0.3s;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`
export const VisualizerPage: React.FC<VisualizerProps> = ({ url }) => {
  const { viewFile } = useArchives();
  const history = useHistory();

  return (
    <body
      style={{
        minHeight: "100vh",
        width: "100%",
        overflow: "hidden",
        margin: "0px",
        backgroundColor: "rgb(82, 86, 89)",
      }}
    >
      <embed
        style={{
          position: "relative",
          left: "0",
          top: "0",
          height: "100%",
          margin: "0",
          padding: "0",
        }}
        width="100%"
        height="100%"
        src={viewFile}
        type="application/pdf"
      ></embed>
      <ClosePdf onClick={history.goBack}>Fechar</ClosePdf>
    </body>
  );
};
