import React from 'react';
import LeftPanel from '../../Components/LeftPanel';
import NavbarRouter from '../../router/navbar.routes';
import { Wrapper } from './styled';
import {BrowserRouter} from 'react-router-dom'

const Navigator: React.FC = () => {
    return(
        <Wrapper>
            <BrowserRouter>
                <LeftPanel/>
                <NavbarRouter/>
            </BrowserRouter>
        </Wrapper>
    )

}

export default Navigator