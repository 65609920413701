import React, { useCallback, useState } from 'react'
import { MainPanel, Container, AvatarImage, Subtitle, Title, LoginButton } from './styled'
import {Form} from '@unform/web'
import InputIcon from '../../Components/InputIcon'
import { BiUser } from 'react-icons/bi'
import {useAuth} from '../../hooks/useAuth'
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MdLockOutline } from "react-icons/md";
import { toast } from "react-toastify";
import CryptoJS from 'crypto-js';

const Profile: React.FC = ()=>{

    const { user, editUser } = useAuth();
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);

    const handleSubmit = useCallback(async (data)=>{
        const {password, confirmPassword} = data;
        
        console.log(password, password.length)
        if (password.length < 6 || password === '') {
            toast.error('A senha deve conter no mínimo 6 caracteres.')
            return
        }
        if (password !== confirmPassword) {
            toast.error('Senhas diferentes.')
            return
        }
        data.password = CryptoJS.SHA256(password).toString()
        delete data.confirmPassword;
        await editUser(data);
    },[])

    return (
        <Container>
            <MainPanel>
                <Title>Alterar Senha</Title>
                <AvatarImage src="https://i.stack.imgur.com/l60Hf.png" alt="Foto de perfil"/>
                <Subtitle>Email: {user.email}</Subtitle>
                <Subtitle>CPF: {user.cpf}</Subtitle>
                <Form onSubmit={handleSubmit}>
                    {/* <InputIcon placeholder="Nome" marginBottom="20px" leftIcon={<BiUser/>} name='username' marginTop="40px"/>
                    <InputIcon placeholder="Email" marginBottom="20px" leftIcon={<BiUser/>} name='email'/>
                    <InputIcon placeholder="Endereço" marginBottom="20px" leftIcon={<BiUser/>} name='endereco'/>
                    <InputIcon placeholder="Telefone" marginBottom="20px" leftIcon={<BiUser/>} name='telefone'/> */}
                    <InputIcon
                        placeholder="Nova senha"
                        marginBottom="20px"
                        leftIcon={<MdLockOutline />}
                        rightIcon={showPass ? (
                            <AiOutlineEyeInvisible />
                          ) : (
                            <AiOutlineEye />
                          )}
                        onClickRight={() => setShowPass(p => !p)}
                        type={showPass ? "text" : "password"}
                        name='password'/>
                    <InputIcon
                        placeholder="Confirme sua senha"
                        marginBottom="20px"
                        leftIcon={<MdLockOutline />}
                        rightIcon={showConfirmPass ? (
                            <AiOutlineEyeInvisible />
                          ) : (
                            <AiOutlineEye />
                          )}
                        onClickRight={() => setShowConfirmPass(p => !p)}
                        type={showConfirmPass ? "text" : "password"}
                        name='confirmPassword'/>
                    <LoginButton>Alterar Senha</LoginButton>
                </Form>
            </MainPanel>
        </Container>
    )
}

export default Profile