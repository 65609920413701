import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Container,
  MainPanel,
  LogoutButton,
  Title,
  Subtitle,
  DocumentsHeader,
  FileExplorer,
  LogoutIcon,
  LoadingCard,
} from "./styled";
import FolderCard from "../../Components/FolderCard";
import { useAuth } from "../../hooks/useAuth";
import { useArchives } from "../../hooks/useArchives";
import moment from "moment";
import "moment/locale/pt-br";
import { useHistory } from "react-router";
import { getUserCompany } from "../../services/api";
import { GoSearch } from "react-icons/go";

const Dashboard: React.FC = () => {
  const { user, signOut } = useAuth();
  const history = useHistory();
  const [ userCompany, setUserCompany ] = useState<string>();
  const {
    atualPath,
    setAtualPath,
    data,
    foldersData,
    isLoading,
    setViewFile,
  } = useArchives();
  const [searchInput, setSearchInput] = useState('');
  const searchFolders = useRef<any>({});

  useEffect(()=>{  
    searchFolders.current = {root: false};
    searchFolders.current[atualPath.id] = true;
  }, [atualPath.id])

  const handleOnClickItem = useCallback(
    (identifier: string) => {
      setViewFile(identifier);
      history.push("/dashboard/view");
      setViewFile(identifier);
    },
    [history, setViewFile]
  );

  const fetchUserCompany = useCallback(async () => {
    const companyName = await getUserCompany(user.email)
    setUserCompany(companyName)
  }, [user]);

  useEffect(() => {
    fetchUserCompany()
  }, [fetchUserCompany])

  const handleBackFolder = () => {
    setAtualPath((atualPath: any) => {
        const dividedLabel = atualPath.label.split('/')
        dividedLabel.pop()
        const newLabelPath = dividedLabel.join('/');
        const folder = foldersData.filter((folder: any) => folder.id.toString() === atualPath.prevPath)
        const newPrevPath = folder.length ? folder[0].attributes.parent_id : 'Raiz'
        return { id: atualPath.prevPath, label: newLabelPath, prevPath: newPrevPath}
    })
  }

  const handleClickFolder = (item: any) => {
    setAtualPath((prevPath: any) => ({
        prevPath: item.attributes.parent_id,
        id: item.id.toString(),
        label: `${prevPath.label}/${item.attributes.name}`,
    }))
  }

  const recursiveFolders = useCallback((folder) => {
    const folders = searchFolders.current;
    try{
      if (folder.attributes.parent_id in folders){
        folders[folder.id.toString()] = folders[folder.attributes.parent_id];
  
        return folders[folder.attributes.parent_id]
      }else{
        const indexFolder = foldersData.findIndex((element: any) => element.id.toString() === folder.attributes.parent_id)
        folders[folder.id.toString()] = recursiveFolders(foldersData[indexFolder])
        return folders[folder.id.toString()];
      }
    }catch{
      return false;
    }
  }, [JSON.stringify(foldersData)])

  const searchDoc = useCallback((doc) => {
    try{
      const indexFolder = foldersData.findIndex((element: any) => element.id.toString() === doc.attributes.parent_folder)
      return recursiveFolders(foldersData[indexFolder])
    }catch{
      return false;
    }

  }, [JSON.stringify(foldersData)])

  return (
    <Container>
      <MainPanel>
        <LogoutButton onClick={signOut}>
          <LogoutIcon />
          SAIR
        </LogoutButton>
        <Title>{userCompany}</Title>
        <Subtitle>
          {moment().format("dddd DD [de] MMMM [de] YYYY").toUpperCase()}
        </Subtitle>
        <DocumentsHeader>
            <div>
                <GoSearch color="white" size={24} />
                <input
                    type="text"
                    placeholder="Buscar"
                    value={searchInput}
                    onChange={(e)=>setSearchInput(e.target.value)}
                    onBlur={()=>setSearchInput('')}
                />
            </div>
            <h1>{'>>'} {atualPath.label.replaceAll("/", " || ")}</h1>
        </DocumentsHeader>
        <FileExplorer>
          {isLoading ? (
            <>
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
              <LoadingCard />
            </>
          ) : (
            <>
              {(atualPath.id !== 'root' && (
                <FolderCard
                    icon="back"
                    name={'Voltar'}
                    onClick={handleBackFolder}
                />
              ))

              }
              {foldersData.map((folder: any, index: number) => {
                if (
                    (folder.attributes.parent_id === atualPath.id && searchInput === '')
                    ||
                    (searchInput !== '' && folder.attributes.name.toLowerCase().includes(searchInput.toLowerCase()) && recursiveFolders(folder))
                  ){
                return (
                  <FolderCard
                    icon="folder"
                    name={folder.attributes.name}
                    onClick={() => handleClickFolder(folder)}
                    key={index}
                  />
                );
              }})}
              {data.map((document: any) => {
                if (
                    (document.attributes.parent_folder === atualPath.id  && document.attributes.name.toLowerCase().includes(searchInput.toLowerCase()))
                    ||
                    (searchInput !== '' && document.attributes.name.toLowerCase().includes(searchInput.toLowerCase()) && searchDoc(document))
                ) {
                    return (
                    <FolderCard
                        name={document.attributes.name}
                        onClick={() => handleOnClickItem(document.attributes.file?.data.attributes.url)}
                        key={document.id+document.attributes.name}
                    />
                    );
                }})}
            </>
          )}
        </FileExplorer>
      </MainPanel>
    </Container>
  );
};

export default Dashboard;
