import React, { useCallback } from "react";
import {
  PanelWrapper,
  AvatarImage,
  SearchInput,
  UserName,
  NavbarMenu,
  MenuItem,
} from "./styled";
import { BiSearchAlt } from "react-icons/bi";
import { NavbarData } from "./navbar.data";
import { useHistory, useLocation } from "react-router-dom";
import { GoFileDirectory } from "react-icons/go";
import { useArchives } from "../../hooks/useArchives";
import { useAuth } from "../../hooks/useAuth";

const LeftPanel: React.FC = () => {
  let location = useLocation();
  const history = useHistory();
  const { atualPath, setAtualPath, foldersData } = useArchives();
  const { user } = useAuth();

  const handleSelectFolder = useCallback(
    (folder: any) => {
      history.push("/dashboard/documents");
      setAtualPath((prevPath: any) => ({
        prevPath: folder.attributes.parent_id,
        id: folder.id.toString(),
        label: `Raiz/${folder.attributes.name}`,
    }))
    },
    [history, setAtualPath]
  );

  const handleChangePage = useCallback(
    (page: string) => {
      history.push(`/dashboard${page}`);
      setAtualPath({id: 'root', label: 'Raiz'});
    },
    [history, setAtualPath]
  );

  return (
    <PanelWrapper>
      <AvatarImage
        src="https://i.stack.imgur.com/l60Hf.png"
        alt="Foto de perfil"
      />
      <UserName>Olá {user.name}!</UserName>

      <NavbarMenu>
        {NavbarData.map((item, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => handleChangePage(item.path)}
              activeItem={location.pathname === `/dashboard${item.path}`}
            >
              {item.icon}
              {item.title}
            </MenuItem>
          );
        })}
        {foldersData
            .filter((folder: any) => folder.attributes.parent_id === 'root')
            .map((folder: any, index: number) => {
                return (
                    <MenuItem
                    key={index}
                    activeItem={folder.attributes.name === atualPath.label.split("/")[0]}
                    onClick={() => handleSelectFolder(folder)}
                    >
                    <GoFileDirectory />
                    {folder.attributes.name}
                    </MenuItem>
                );
        })}
      </NavbarMenu>
    </PanelWrapper>
  );
};

export default LeftPanel;
