import styled from "styled-components";

export const CardWrapper = styled.div`
  width: 200px;
  height: 200px;
  min-width: 200px;
  min-height: 200px;
  background-color: rgba(41, 87, 164, 0.3);
  border: 2px solid white;
  transition: all ease 0.3s;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  padding: 0 10px;

  svg {
    width: 100px;
    height: 100px;
    color: white;
  }

  h1 {
    font-size: 16px;
    font-weight: 500;
    color: white;
    text-align: center;
    width: 100%;
    word-break: break-all;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:hover {
    background-color: rgba(41, 87, 164, 1);
  }
`;
